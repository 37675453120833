div:focus {
  outline-width: 0;
}

a {
  color: #282828;
  font-weight: 600;

  &:hover {
    color: #64867e;
    text-decoration: none;
    font-weight: 600;
  }
}

body {
  p {
    font-family: Open Sans;
  }

  h1 {
    font-weight: 600;
  }
}

.container {
  .header {
    padding: 45px 0 5px;
    border-bottom: 1px solid #ddd;

    span {
      font-size: 14px;
      float:right;
      padding: 24px 0;
      font-weight: 300;
    }
  }

  .section-header {
    // border-top: 7px solid #eee;
    // border-radius: 5px;
    // padding-top: 10px;

    h3 {
      border-bottom: 1px solid #ddd;
      padding: 0 0 5px;
    }

    span {
      font-size: 14px;
      float:right;
      padding: 14px 0;
      font-weight: 300;
    }
  }

  .img-square {
    p {
      font-size: 14px;
    }
  }

  div.border {
    border: 10px solid #333 !important;
    box-shadow: 5px 5px 10px #ccc;
  }

  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 2px;
    transition: box-shadow .2s;

    &.full {
      height: auto;
      border: 35px solid white;

      &:hover {
        box-shadow: none;
      }
    }

    &.bottom {
      object-position: bottom;
    }

    &.top {
      object-position: top;
    }

    &.right {
      object-position: right;
    }

    &.left {
      object-position: left;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px #bbb;
      transition: box-shadow .2s;
    }
  }
}

.photobox {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  .background {
    z-index: 101;
    background: black;
    opacity:75%;
    width: 100vw;
    height: 100vh;

    &:hover {
      cursor: pointer;
    }
  }

  .closer {
    position: absolute;
    top: 0;
    right: -25px;
    color: #aaa;

    &:hover {
      cursor: pointer;
    }
  }

  .photocontainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 102;
    margin: 0 auto;

    img {
      max-width: 90vw;
      max-height: 90vh;
      border:20px solid white;
    }

    p {
      color: #aaa;
      padding-top: 5px;
    }
  }
}

.copyright {
   font-size: 12px;
   color: grey;
}

@media (max-width: 992px) {
  span {
    display: block !important;
    float: none !important; 
    font-size: 12px !important;
    padding: 5px 0 !important;
  }
  .container {
    .header {
      padding: 15px 0;
    }
  }

  br.mobile {
    display: none;
  }

  h1 {
    font-size: 28px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }

  .closer {
    display: none !important;
  }

  .photocontainer {
    img {
      border: 6px solid white !important;
    }
  }
}